<template>
  <div>
    <a-card title="工序检验详情">
      <template slot="extra">
        <a-space>
          <a-button type="primary" ghost icon="printer" v-print="'#printContent'"> 打印</a-button>
          <a-button type="primary" ghost icon="left" @click="$router.go(-1)">返回</a-button>
        </a-space>
      </template>

      <section id="printContent">
        <a-spin :spinning="retrieveLoading">
          <img id="barcode" style="float: right" />
          <a-descriptions bordered>
            <a-descriptions-item label="工序检验单号">
              {{ dataItem.number }}
            </a-descriptions-item>
            <a-descriptions-item label="生产工单号">
              {{ dataItem.production_order_number }}
            </a-descriptions-item>
            <a-descriptions-item label="检验工序">
              {{ dataItem.inspection_process }}
            </a-descriptions-item>
            <a-descriptions-item label="检验日期">
              {{ dataItem.inspection_date }}
            </a-descriptions-item>
            <a-descriptions-item label="检验人">
              {{ dataItem.inspector_name }}
            </a-descriptions-item>
            <a-descriptions-item label="检验结果">
              {{ dataItem.inspection_result_display }}
            </a-descriptions-item>
            <a-descriptions-item label="检验报告">
              {{ dataItem.inspection_content }}
            </a-descriptions-item>
            <a-descriptions-item label="合格数量">
              {{ dataItem.qualified_quantity }}
            </a-descriptions-item>
            <a-descriptions-item label="不合格数量">
              {{ dataItem.unqualified_quantity }}
            </a-descriptions-item>
            <a-descriptions-item label="备注">
              {{ dataItem.remark }}
            </a-descriptions-item>
            <a-descriptions-item label="创建人">
              {{ dataItem.creator_name }}
            </a-descriptions-item>
            <a-descriptions-item label="创建时间">
              {{ dataItem.create_time }}
            </a-descriptions-item>
            <a-descriptions-item label="附件">
              <div v-for="fileItem in dataItem.process_inspection_file_items" :key="fileItem.id">
                <a :href="fileItem.file" target="_blank" style="margin-right: 16px">{{ fileItem.name }}</a>
              </div>
            </a-descriptions-item>
          </a-descriptions>
        </a-spin>
      </section>
    </a-card>
  </div>
</template>

<script>
import { processInspectionReportRetrieve } from "@/api/inspection";
import JsBarcode from "jsbarcode";

export default {
  data() {
    return {
      dataItem: {},
      retrieveLoading: false,
    };
  },
  methods: {
    initData() {
      this.retrieveLoading = true;
      processInspectionReportRetrieve({ id: this.$route.query.id })
        .then((data) => {
          this.dataItem = data;
          this.getJsBarcode(this.dataItem.number);
        })
        .finally(() => {
          this.retrieveLoading = false;
        });
    },
    getJsBarcode(number) {
      JsBarcode("#barcode", number, {
        lineColor: "#000",
        width: 2,
        height: 40,
        displayValue: true,
      });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
<style></style>
